import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../css/scoreboard.css'
import firstBy from 'thenby';
import { Button, Modal } from 'react-bootstrap'
import { fetchEntriesByPickemGameId, fetchGameByGameId, getSetScoreboard } from '../actions'
import { getTeamLogoImageUrlFromAbbr, getTeamLogoPath, getWeeklyScrollbarItems, isFeatureEnabled, FEATURE_FLAGS, getCbsCompositeGameAbbr, getLastScoredText } from '../scripts/pickem-helpers'
import { getCookieValue } from '../helpers';
import moment from 'moment-timezone'
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { PickemScrollbarCard } from "./PickemScrollbarCard";
import { LeftArrow, RightArrow } from "./Arrows";
import io from 'socket.io-client'

import ScoreboardSeason from './ScoreboardSeason/ScoreboardSeason';

class Scoreboard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentPlayer: {},
            showSinglePickModal: false,
            showSinglePlayerPickemPicks: false,
            setShow: false,
            seasonStartDate: "8/31/2022", // used for football pickems to create the Weekly Scrollbar with dates
            gameId: this.props.location.pathname.split('/').pop(), // get the gameId from the url so we can get the data we want
            liveGames: [],
            currentScoreboardMode: this.SCOREBOARD_MODES.SINGLE_GAME_ALL,
            currentCellModalGame: { game: {} },
            timezoneString: "America/New_York",
            currentPlayerEntryId: null,
            setId: 4
        }
    }

    SCOREBOARD_MODES = {
        SINGLE_GAME_ALL: 'week-all',
        SINGLE_GAME_KIDS: 'week-kids',
        SEASON: 'season',
    }

    componentDidMount = async () => {
        // look at the cookie to see if the user has an entry this week
        const entryId = getCookieValue(document.cookie, "entry_id")
        this.setState({ currentPlayerEntryId: entryId })

        // get game info
        await this.props.fetchEntriesByPickemGameId(this.state.gameId)
        await this.props.fetchGameByGameId(this.state.gameId);

        // get scoreboard
        this.setState({ seasonData: await this.props.getSetScoreboard(this.state.setId) });

        // get current user userId
        const userId = this.props.gameEntries.find(x => x.id === entryId)?.userId
        this.setState({ currentPlayerUserId: userId })

        if (this.props.game)
            this.state.isScoringLive = moment().unix() * 1000 > this.props.game.cutOffUnixMs

        this.setState({
            // setup the socket.io client
            socket: isFeatureEnabled(this.props.game, process.env.NODE_ENV, FEATURE_FLAGS.LIVE_GAME_UPDATES) ? io("http://localhost:3002") : null
        })

        // prepare the Live Game feature by initializing objects for each game with the CBS Game Id as the key and adding it to `liveGames`
        if (this.props.game && isFeatureEnabled(this.props.game, process.env.NODE_ENV, FEATURE_FLAGS.LIVE_GAME_UPDATES)) {
            this.setState({
                liveGames: this.props.game.questions.map(question => {
                    const game = question.game;

                    // the CBS Socket Service uses a different game key (`abbr`) than what the Yahoo service uses (where we fetch games from for SuperIndy).
                    // We first create a composite key to one of the CBS Socket Service properties ('abbr') by which we can match the games up
                    // NOTE: It MUST be put into uppercase to match what comes from CBS
                    const key = getCbsCompositeGameAbbr(game)

                    // initialize the game
                    return { key, data: {} };
                })
            })
        }

        // sort all entries by the game time start, if it's a GameType=3 (Football)
        // FIRST sort the games by time so we know what order they should be in.
        // SECOND sort each user's picks (where isTest: false) so they're in the same `id` order as the games
        if (this.props.game.gameTypeId === 3 || this.props.game.gameTypeId === 4) {
            this.props.game.questions.sort((a, b) => a.game.startTimeUnixMs - b.game.startTimeUnixMs)
            let orderedGameIdsByStartTime = {};

            // get all the ids from the now-ordered `questions` and put them in the right index order (assigns them to an OBJECT where each one is `gameId: index` or 339: 8)
            this.props.game.questions.map(x => x.id).forEach((id, index) => orderedGameIdsByStartTime[id] = index)

            // sort each user's picks by this ordered game id object (don't sort the Test or Incomplete entries)
            this.props.gameEntries.filter(x => !x.isTest && !x.isIncomplete).forEach(entry => entry.picks.sort((a, b) => orderedGameIdsByStartTime[a.id] - orderedGameIdsByStartTime[b.id])
            )
        }

        // only run socket stuff is `liveGameUpdates` is enabled
        if (this.props.game && this.state.socket && isFeatureEnabled(this.props.game, process.env.NODE_ENV, FEATURE_FLAGS.LIVE_GAME_UPDATES)) {
            this.state.socket.on('welcome', (msg) => {
                console.log(msg);
            })
            this.state.socket.on('gameUpdate', (gameUpdates) => {
                console.log("data", gameUpdates);

                // copy the state's liveGames array for immutability
                let newGames = this.state.liveGames.slice() //copy the array

                // messages will come in a `body.games` Array with the new updates (sometimes it's only 1 game, sometimes it's 10). find each game in `liveGames` by the gameId key and update it's record (no need to keep history - just overwrite what's there)
                // loop through all the updated games
                for (let i = 0, game = gameUpdates.data.body.games[i]; i < gameUpdates.data.body.games.length; i++) {
                    let index = newGames.findIndex(x => x.key === game.abbr.toUpperCase()); // by default, CBS is upper, but force it to be sure since we set our composite key above in upper to match
                    newGames[index] = { ...newGames[index], data: game, seqNum: gameUpdates.data.seqNum } // replace the object
                }

                // now that all the games are update, save them to the state
                this.setState({ liveGames: newGames }, () => {
                    console.log("newState", this.state.liveGames);
                }) // set new state
            })
        }

        this.setState({
            scores: {
                entries: this.props.gameEntries.filter(x => !x.isTest && !x.isIncomplete).sort(
                    firstBy("totalScore", { direction: -1 }).thenBy((a, b) => a.firstName.localeCompare(b.firstName))
                )
            },
            game: this.props.game
        }, () => {
        });
    }

    handleCardItemClick = (idx) => {
        console.log('clicked' + idx)

        // push the url into the browser's history/url bar
        // this.props.history.push("/scores/" + 24);

    }

    handleShowPlayerPickemPicksModal = () => this.setState({ showSinglePlayerPickemPicks: true })
    handleClosePlayerPickemPicksModal = () => this.setState({ showSinglePlayerPickemPicks: false })

    handleShowSinglePickModal = () => this.setState({ showSinglePickModal: true })
    handleCloseSinglePickModal = () => this.setState({ showSinglePickModal: false })

    showQandA = (pick, answer, entry, game) => {
        const playerPick = entry.picks.find(x => x.id === pick.id);
        const propBet = this.state.game.questions.find(x => x.id === pick.id)

        // sometimes we have rows of drivers (an array or arrays). flatten that if it's there
        let options = propBet.options;
        if (Array.isArray(propBet.options[0])) {
            options = options.reduce((a, b) => { return a.concat(b) }, [])
        }

        this.setState({
            playerName: entry.firstName,
            isCorrect: answer ? answer.isCorrect : null,
            isCanceledQuestion: answer ? answer.isCanceledQuestion : null,
            canceledReason: answer ? answer.canceledReason : null,
            questionText: propBet.text,
            answerText: propBet.correctAnswers && propBet.correctAnswers.map(id => options.find(x => x.id === id).text).join(', '),
            answerExplainer: propBet.answerExplainer,
            value: propBet.value,
            pickText: options.filter(x => playerPick.selections.includes(x.id)).map(x => x.text).join(', '),
            currentCellModalGame: game
        }, this.handleShowSinglePickModal());
    }

    showQuestionHeaderInfo = (fullGame, answer) => {
        // get the break of picks between players (with percentages)

        // this.setState({
        //     playerName: entry.firstName,
        //     isCorrect: answer ? answer.isCorrect : null,
        //     questionText: propBet.text,
        //     answerText: propBet.correctAnswers.map(id => options.find(x => x.id === id).text).join(', '),
        //     answerExplainer: propBet.answerExplainer,
        //     value: propBet.value,
        //     pickText: options.filter(x => playerPick.selections.includes(x.id)).map(x => x.text).join(', ')
        // }, this.handleShowSinglePickModal());
    }



    showPlayerPickemPicks = (entry) => {
        // only enable showing picks if we've set the game's isShowPlayerPicksAfterCutoff=true
        if (this.state.game.isShowPlayerPicksAfterCutoff || moment().unix() * 1000 > this.state.game.cutOffUnixMs) {
            this.setState({
                currentPlayer: entry
            }, this.handleShowPlayerPickemPicksModal())
        }
    }

    getRowClassName = (entry) => {
        let className = "highlight-score-row"

        // check if the entry is a leader - highlight if so
        if ((entry.place === "1" || entry.place === "T1") && this.state.game.questionsScoredCount > 0)
            className += " highlight-winner-row"

        // check if this row is the user viewing the page (taken from cookie and/or their logged-in account info)
        if (entry.id === this.state.currentPlayerEntryId)
            className += " current-user-scoreboard-row"

        return className
    }

    renderPlayerModalPickemRows = (game, entry) => {
        return entry.picks && entry.picks.map(pick => {
            // lookup the game and get info off it to set the row
            const thisGame = game.questions.find(x => x.id === pick.id)
            const thisScoredPick = entry.scores && entry.scores.find(x => x.id === pick.id)

            let gameScoreIcon;
            if (thisScoredPick) {
                if (!thisScoredPick.isCanceledQuestion) {
                    if (thisScoredPick.isCorrect)
                        gameScoreIcon = <i className="fas fa-check-circle pick-right"></i>
                    else
                        gameScoreIcon = <i className="fas fa-times-circle pick-wrong"></i>
                } else { // canceled
                    gameScoreIcon = <i className="fas fa-ban question-canceled"></i>

                }
            }

            switch (game.gameTypeId) {
                case 2:
                    return <tr className="picks-modal-row" onClick={() => { this.showQandA(pick, thisScoredPick, entry) }} key={thisGame.id}>
                        <td style={{ padding: "0px", paddingTop: "5px", marginTop: "5px" }}>{gameScoreIcon}</td>
                        <td>
                            <span className={thisGame.isCanceledQuestion ? "player-picks-question-canceled" : ""}>{thisGame.questionBoxTitle}</span>
                            <span style={!thisGame.isCanceledQuestion ? { display: 'none' } : { display: 'block' }}>CANCELED</span>
                        </td>
                        <td className={thisGame.isCanceledQuestion ? "player-picks-question-canceled" : ""}>{thisGame.value}</td>
                        <td className={thisGame.isCanceledQuestion ? "player-picks-question-canceled" : ""}>{thisGame.options.filter(x => pick.selections.includes(x.id)).map(x => x.text).join(', ')}</td>
                    </tr>
                case 3:
                case 4:
                    return <tr key={thisGame.id} className={thisGame.isCanceledQuestion ? "player-picks-question-canceled" : ""}>
                        <td style={{ padding: "0px", paddingTop: "5px", marginTop: "5px" }}>{gameScoreIcon}</td>
                        <td>{thisGame.game.awayTeam.abbr} v {thisGame.game.homeTeam.abbr}</td>
                        <td>{this.renderPlayerTeamPick(thisGame, pick, entry)}</td>
                        <td>{`${moment(thisGame.game.startTimeUnixMs).format("ddd h:mm")}, ${thisGame.game.tvCoverage}`}</td>
                    </tr>
                default:
                    return <div></div>;
            }
        })
    }

    renderPlayerTeamPick = (game, pick, entry) => {
        if (game.isCanceledQuestion)
            return "GAME CANCELED"
        else {
            let gameText;
            switch (game.layoutId) {
                case 5: //ATS
                    gameText = game.homeSpread < 0 ? (pick.selections[0] === 1 ? game.game.homeTeam.abbr + " (" + game.homeSpread + ")" : game.game.awayTeam.abbr + " (+" + game.homeSpread.toString().replace("-", "") + ")") : (pick.selections[0] === 1 ? game.game.awayTeam.abbr + " (-" + game.homeSpread + ")" : game.game.homeTeam.abbr + " (+" + game.homeSpread.toString().replace("-", "") + ")")
                    break;
                case 6:  //over/under
                    gameText = pick.selections[0] === 1 ? `Over ${game.overUnder}` : `Under ${game.overUnder}`
                    break;
                default:

                    break;
            }

            return <span><img className="player-picks-team-logo" src={`${getTeamLogoPath(game.game)}/${getTeamLogoImageUrlFromAbbr(game.game, pick.pickedTeamAbbr, "svg")}`} alt={`${pick.pickedTeamAbbr} team logo`} /> {gameText}</span>
        }
    }

    getScoreboardCellClasses(game, scoredPick, layoutId) {
        const nowTimeMs = moment().unix() * 1000
        let className = "scoreboard-cell ";

        // add class if the pick has NOT been scored
        // Case 1: Game currently underway (startTime < now but not scored)
        // Case 2: Game in the future (startTime > now)
        if (scoredPick == null) {
            // style a game currently underway
            if (game.game.startTimeUnixMs < nowTimeMs) {
                className += " scoreboard-team-logo-underway-not-scored"
            }
            else if (game.game.startTimeUnixMs > nowTimeMs) {
                switch (layoutId) {
                    case 5:
                        className += " scoreboard-team-logo-future-not-scored"
                        break;
                    case 6:
                        className += " scoreboard-team-logo-future-not-scored"
                        break;
                    default:
                        break;
                }
            }

            // return immediately - it hasn't been scored and has no WRONG/RIGHT
            return className;
        } else { // game has been scored
            className += " scoreboard-game-scored"
        }
    }

    getImagePickClassNames(game, scoredPick, layoutId) {
        let className = "scoreboard-team-logo ";

        // get the base class name
        switch (layoutId) {
            case 5: // checkmark/red x
                className += "td-image-pick"
                break;
            case 6: // logo image
                className += "scoreboard-team-logo-over-under"
                break;
            default:
                break;
        }


        // // add class if the pick has NOT been scored
        // // Case 1: Game currently underway (startTime < now but not scored)
        // // Case 2: Game in the future (startTime > now)
        // if (scoredPick == null) {
        //     // style a game currently underway
        //     if (game.game.startTimeUnixMs < nowTimeMs) {
        //         className += " scoreboard-team-logo-underway-not-scored"
        //     } 
        //     else if (game.game.startTimeUnixMs > nowTimeMs) {
        //         switch (layoutId) {
        //             case 5:
        //                 className += " scoreboard-team-logo-future-not-scored"
        //                 break;
        //             case 6:
        //                 className += " scoreboard-team-logo-future-not-scored"
        //                 break;
        //             default:
        //                 break;
        //         }
        //     }

        //     // return immediately - it hasn't been scored and has no WRONG/RIGHT
        //     return className;
        // } else { // game has been scored
        //     className += " scoreboard-game-scored"
        // }

        // add class if the pick is WRONG
        if (scoredPick) {
            if (!scoredPick.isCanceledQuestion && !scoredPick.isCorrect)
                className += " scoreboard-team-logo-scored-wrong"

            // add class if the pick is RIGHT
            if (scoredPick.isCorrect)
                className += " scoreboard-team-logo-scored-right"

            // add class if the question has been CANCELED
            if (scoredPick.isCanceledQuestion)
                className += " scoreboard-team-logo-question-canceled"
        }

        return className
    }

    renderPickemPlayerGamePickImage(thisGame, pick, thisScoredPick, ext) {
        const imagePath = `${getTeamLogoPath(thisGame.game)}/${(thisScoredPick && !thisScoredPick.isCorrect) || thisScoredPick == null ? "grayscale/" : ""}${getTeamLogoImageUrlFromAbbr(thisGame.game, pick.pickedTeamAbbr, ext)}`

        return <img className={this.getImagePickClassNames(thisGame, thisScoredPick, thisGame.layoutId)} src={imagePath} alt={`${pick.pickedTeamAbbr} team logo`} />
    }

    renderPlayerScoreboardRow(game, entry) {
        // only render the picks if the entry deadline is past
        if (this.state.game.isShowPlayerPicksAfterCutoff || moment().unix() * 1000 > this.state.game.cutOffUnixMs) {
            switch (game.scoreboardTypeId) {
                case 1: // normal "green check" scoreboard
                    if (entry.picks) {
                        // order the picks from this entry in the same order as the questions with its `order` property
                        let order = {};
                        game.questions.sort((a, b) => a.order - b.order).map(x => x.id).forEach((a, idx) => order[a] = idx)
                        entry.picks.sort(function (a, b) {
                            return order[a.id] - order[b.id];
                        });

                        return entry.picks.map((pick, idx) => {

                            const thisScoredPick = entry.scores.find(x => x.id === pick.id)

                            // if we haven't scored the pick yet, use a neutral icon for the icon display
                            let iconClasses;
                            let keyPrefix;
                            if (!thisScoredPick) {
                                iconClasses = "fas fa-circle-notch pick-not-scored"
                                keyPrefix = "not-scored";
                            } else if (thisScoredPick.isCorrect) {
                                iconClasses = "fas fa-check answer-right"
                                keyPrefix = "right";
                            } else if (thisScoredPick.isCanceledQuestion) {
                                iconClasses = "fas fa-ban question-canceled"
                                keyPrefix = "canceled";
                            } else {
                                iconClasses = "fas fa-times answer-wrong"
                                keyPrefix = "wrong";
                            }

                            return (
                                <td key={idx} className="scoreboard-icon-pick-result" onClick={() => { this.showQandA(pick, thisScoredPick, entry) }}><i key={keyPrefix + idx} className={iconClasses} ></i></td>
                            )
                        })
                    } else return ""
                case 2: // image pick scoreboard (e.g. team logos)
                    return entry.picks && entry.picks.map((pick, idx) => {
                        // get the sport `game`
                        const thisGame = game.questions.find(x => x.id === pick.id)
                        const thisScoredPick = entry.scores && entry.scores.find(x => x.id === pick.id)

                        // there are different question types: e.g. regular pickem games against the spread (layoutId: 5) and over/under. Render each correctly.
                        switch (thisGame.layoutId) {
                            case 5:
                                return (
                                    <td className={this.getScoreboardCellClasses(thisGame, thisScoredPick, thisGame.layoutId)} key={idx} onClick={() => { this.showQandA(pick, thisScoredPick, entry, thisGame) }}>
                                        <div className="scoreboard-team-logo-container">
                                            {this.renderPickemPlayerGamePickImage(thisGame, pick, thisScoredPick, "png")}
                                            {thisScoredPick && !thisScoredPick.isCanceledQuestion && !thisScoredPick.isCorrect && <div className="scoreboard-team-logo-scored-wrong-x"></div>}
                                        </div>
                                    </td>
                                )
                            case 6:
                                return (
                                    <td key={idx} onClick={() => { this.showQandA(pick, thisScoredPick, entry) }}>
                                        <div className="over-under-pick">
                                            <div className="over-under-team-logos">
                                                {this.renderPickemPlayerGamePickImage(thisGame, pick, thisScoredPick, "png")}
                                                <img className={this.getImagePickClassNames(thisGame, thisScoredPick, thisGame.layoutId)} src={`${getTeamLogoPath(thisGame.game)}/${getTeamLogoImageUrlFromAbbr(thisGame.game, thisGame.game.homeTeam.abbr, "png")}`} alt={`${thisGame.game.homeTeam.abbr} team logo`} />
                                            </div>
                                            <div className={thisScoredPick != null ? (thisScoredPick.isCorrect ? "over-under-right" : "over-under-wrong") : "over-under-not-scored"}>{pick.selections[0] === 1 ? "Over" : "Under"}</div>
                                        </div>
                                    </td>
                                );
                            default:
                                return <td></td>;
                        }

                    })
                default:
                    break;
            }
        }
    }

    renderWeeklyScrollBar() {
        const items = getWeeklyScrollbarItems(this.state.seasonStartDate, this.state.game.setGameId)

        // const centerOnInit = ({
        //     getItemById,
        //     scrollToItem,
        //     visibleItems
        // }) => {
        //     const { center: centerItemKey } = getItemsPos(visibleItems);
        //     scrollToItem(getItemById(centerItemKey), "auto", "center");
        // };



        return (<ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
        // onInit={centerOnInit}

        >
            {items.map(({ weekNumber, weekDayRange }, idx) => (
                <PickemScrollbarCard
                    title={"Week " + weekNumber}
                    subtitle={weekDayRange}
                    itemId={idx} // NOTE: itemId is required for track items
                    key={idx}
                    selected={weekNumber === this.state.game.setGameId}
                    // onClick={() => { this.props.history.push("/scores/" + this.state.gameId) }}
                    onClick={this.handleCardItemClick}
                />
            ))}
        </ScrollMenu>)
    }

    renderStatBox = (statLabel, statNumber, cssClassStyle) => {
        return <div className={`scoreboard-stat-box ${cssClassStyle && cssClassStyle.replace('.', '')}`}>
            <strong>{statLabel}</strong>
            <span>{statNumber}</span>
        </div>
    }

    renderQuestionHeaderContent = (questionScoring) => {
        {/* <i class="fa-solid fa-clock"></i> */ }
        if (questionScoring) {
            // immediately check if question is canceled, this takes precedence over all
            if (questionScoring.isCanceledQuestion)
                return <i className="fas fa-ban th-pick-header-question-canceled"></i>
            else
                return <i className="fas fa-check-circle th-pick-header-game-scored"></i>
        } else { // question not scored

        }
    }

    renderScoreboardBoxes = () => {
        return this.state.game &&
            <div className="scoreboard-box-stats-container mt-2">
                {this.renderStatBox("Entries", this.state.scores.entries.length)}
                {this.renderStatBox(this.state.game.scoreboardTypeId === 2 ? "Games" : "Questions", this.state.game.questions.length)}
                {this.renderStatBox("Scored", this.state.game.questionsScoredCount - this.state.game.questions.filter(x => x.isCanceledQuestion).length ?? 0)}
                {this.state.game.questions.filter(x => x.isCanceledQuestion).length > 0 && this.renderStatBox("Canceled", this.state.game.questions.filter(x => x.isCanceledQuestion).length, "scoreboard-stat-box-canceled")}
            </div>
    }

    handleScoreboardModeClick = (mode) => {
        this.setState({ currentScoreboardMode: mode })
    }

    renderSeasonScoreboard = () => {
        return (
           this.state.seasonData && <div className="season-scoreboard">
                <ScoreboardSeason data={this.state.seasonData} userId={this.state.currentPlayerUserId} />
            </div>
        )
    }

    renderKidsScoreboard = () => {
        return (
            <div className="kids-scoreboard">
                Kids Scoreboard
            </div>
        )
    }

    renderScoreboardTypeButtons = () => {
        return this.state.game && (
            <>
                <div className="scoreboard-button-types mt-1 mb-2 ml-2">
                    <div className="scoreboard-types-text mr-2">SCOREBOARDS</div>
                    <button className={`btn btn-sm btn-secondary btn-rounded mr-1 ${this.state.currentScoreboardMode === this.SCOREBOARD_MODES.SINGLE_GAME_ALL ? "scoreboard-mode-active" : ""}`} onClick={() => this.handleScoreboardModeClick(this.SCOREBOARD_MODES.SINGLE_GAME_ALL)}>Full</button>
                    <button className={`btn btn-sm btn-secondary btn-rounded mr-1  ${this.state.currentScoreboardMode === this.SCOREBOARD_MODES.SINGLE_GAME_KIDS ? "scoreboard-mode-active" : ""}`} onClick={() => this.handleScoreboardModeClick(this.SCOREBOARD_MODES.SINGLE_GAME_KIDS)}>Kids</button>
                    <button className={`btn btn-sm btn-secondary btn-rounded mr-1  ${this.state.currentScoreboardMode === this.SCOREBOARD_MODES.SEASON ? "scoreboard-mode-active" : ""}`} onClick={() => this.handleScoreboardModeClick(this.SCOREBOARD_MODES.SEASON)}>Season</button>
                </div>
                <hr />
            </>
        )
    }

    getScoreboardEntries = (filters) => {
        let entries = [];
        if (this.state.scores != null && this.state.scores.entries != null) {
            entries = this.state.scores.entries

            if (filters.isKidsOnly && this.state.currentScoreboardMode === this.SCOREBOARD_MODES.SINGLE_GAME_KIDS) {
                entries = this.state.scores.entries.filter(x => x.isKid)
            }
        }
        return entries
    }

    renderScores(filters) {
        const entries = this.getScoreboardEntries(filters)

        return (<div className="table-responsive scoreboard-container">
            <table className="table table-small table-scores">
                <thead>
                    <tr>
                        {this.state.scores && this.state.scores.entries && this.state.scores.entries[0] && this.state.scores.entries[0].picks && this.state.isScoringLive && <th className="scoreboard-place-th">Place</th>}
                        <th className="scoreboard-score-th">Name</th>
                        {this.state.game && this.state.game.isShowTwitter && <th>Twitter</th>}
                        {this.state.isScoringLive && <th className="scoreboard-score-th text-center">Score</th>}
                        {this.state.isScoringLive && this.state.scores != null && this.state.scores.entries.length > 0 && this.state.scores.entries[0].picks && this.state.scores.entries[0].picks.map((answer, idx) => {
                            const questionScoring = this.state.scores.entries[0].scores.find(x => x.id === answer.id)
                            return <th className="th-pick-header" key={idx} onClick={() => { this.showQuestionHeaderInfo(this.state.game, answer) }}>{idx + 1} {this.renderQuestionHeaderContent(questionScoring)}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {entries.map(entry => {
                        return (
                            <tr key={entry.created} className={this.getRowClassName(entry)}>
                                {this.state.isScoringLive && <td className="scoreboard-place">{entry.place ?? "---"}</td>}
                                <td className="name-td" onClick={() => { this.showPlayerPickemPicks(entry) }}><span className="scoreboard-name">{entry.firstName}</span><br /><span className="scoreboard-team-name">{entry.teamName}</span></td>
                                {this.state.game.isShowTwitter && <td className="scoreboard-name">{entry.twitterName !== null && entry.twitterName !== "" ? "@" + entry.twitterName.replace("@", "") : ""}</td>}
                                {this.state.isScoringLive && <td className="scoreboard-score">{entry.totalScore}</td>}
                                {this.renderPlayerScoreboardRow(this.state.game, entry)}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
        )
    }

    renderGameInstructionText(game) {
        switch (game.scoreboardTypeId) {
            case 2:
                return (
                    <>
                        <div>* Tap a name to see all picks + game schedule</div>
                        <div className="mt-1">* Tap team logos to see game, result, + points</div>
                    </>
                )
            case 1:
            default:
                return `Tap any checkmark or "x" to see the question, result, and point value.`;
        }
    }

    renderPlayerPicksModalBody = (game, currentPlayer) => {
        if (game) {
            switch (game.gameTypeId) {
                case 2:
                    return <div className="scoreboard-picks-modal">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Question</th>
                                    <th>Value</th>
                                    <th style={{ width: "25%" }}>Pick</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderPlayerModalPickemRows(game, currentPlayer)}
                            </tbody>
                        </table>
                    </div>
                case 3:
                case 4:
                    return <div className="scoreboard-picks-modal">
                        <div className="picks-modal-intro-text mb-1">
                            <div>(-) scores: team picked to win by number or more</div>
                            <div>(+) scores: team picked to win OR lose by &lt; number</div>
                        </div>

                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Game</th>
                                    <th>Pick</th>
                                    <th>Time (ET)/TV</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderPlayerModalPickemRows(game, currentPlayer)}
                            </tbody>
                        </table>
                    </div>
                default:
                    break;
            }
        }
    }

    renderPicksModalWeekDates(startUnix, endUnix) {
        return `${moment(startUnix).format("MMM D")}`
    }

    renderCellModalBroadcastInfo() {
        switch (this.state?.game?.gameTypeId) {
            case 3:
                return <div className="broadcast-info-box">
                    <div className="qa-header">BROADCAST INFO</div>
                    <div className="qa-text">{this.state.currentCellModalGame.game.tvCoverage} {moment(this.state.currentCellModalGame.game.startTimeUnixMs).tz(this.state.timezoneString).format("h:mma z")}</div>
                </div>
            default:
                break;
        }
    }

    renderSingleGameScoreboard = (filters = {}) => {
        return (
            this.state.gameId >= 35 && <div className="scoreboard-single-game-container">
                <div className="scoreboard-header">
                    <div className="scoreboard-match-name">
                        {this.state.game && this.state.game.gameName} Scoreboard
                    </div>
                    {/* {<LiveScoreSocketClient />} */}
                    {this.state.isScoringLive &&
                        <div className="scoreboard-instructions-text mt-1">{this.state.game && this.renderGameInstructionText(this.state.game)}</div>}

                    {this.renderScoreboardBoxes()}

                    <div className="last-scored-text">{this.props.game && this.state.isScoringLive && this.props.game.lastScoredUnixtime && `Last Scored: ${getLastScoredText(this.props.game)}`}</div>

                    <div className="set-scrollbar-container">
                        {/* {this.state.game && this.renderWeeklyScrollBar()} */}
                    </div>

                    {this.props.game && isFeatureEnabled(this.props.game, process.env.NODE_ENV, FEATURE_FLAGS.LIVE_GAME_UPDATES) && (
                        <div>
                            <button className="btn btn-primary btn-am-xxs" onClick={() => { this.state.socket.emit("getTestGameUpdate") }}>Get Game</button>
                            <table>
                                <tbody>
                                    {this.state.liveGames.filter(game => game.data && game.data.game_status).map(game => (
                                        <tr key={`game${game.data.id}-seq${game.seqNum}`}>
                                            {/* <td>{game.data.}</td> */}
                                            <td>
                                                {game.data.game_status.last_play && (game.data.game_status.last_play.description)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div >)
                    }
                    {/* {this.state.game.isShowScoreboardMessage && <div className="scoreboard-message">
                <ul>
                    <li>Tap any checkmark or "x" to see the question, result, and point value.</li>
                    <li>Thanks again!! It's fun to have all of you play! If you have suggestions to make this better, let me know! Much love! -andy</li>
                </ul>
            </div>
            }
            {!this.state.game.isShowScoreboardMessage && <div>Tap any checkmark or "x" to see the question, result, and point value.</div>} */}
                </div >
                <Modal show={this.state.showSinglePickModal} onHide={() => this.handleCloseSinglePickModal()}>
                    <Modal.Header closeButton>
                        <></>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="qa-header">Player Name</div>
                        <div className="qa-text">{this.state.playerName}</div>
                        <div className="qa-header">Player Result</div>
                        <div className="qa-text">{this.state.isCorrect == null || this.state.isCanceledQuestion ? <i className="fas fa-ban question-canceled"></i> : this.state.isCorrect ? <i className="fas fa-check answer-right" ></i> : <i className="fas fa-times answer-wrong"></i>} {this.state.isCorrect == null ? "NOT SCORED YET" : (this.state.isCanceledQuestion ? "QUESTION CANCELED" : this.state.isCorrect ? " CORRECT" : "WRONG")}</div>
                        <div className="qa-header">Question</div>
                        <div className="qa-text">{this.state.questionText}</div>
                        <div className="qa-header">Player Pick</div>
                        <div className="qa-text">{this.state.pickText}</div>
                        <div className="qa-header" style={this.state.isCorrect == null || this.state.isCanceledQuestion ? { display: 'none' } : { display: 'block' }}>Answer</div>
                        <div className="qa-text" style={this.state.isCorrect == null || this.state.isCanceledQuestion ? { display: 'none' } : { display: 'block' }}>{this.state.answerText}</div>
                        <div className="qa-header" style={this.state.isCorrect == null ? { display: 'none' } : { display: 'block' }}>Explainer</div>
                        <div className="qa-text" style={this.state.isCorrect == null ? { display: 'none' } : { display: 'block' }}>{this.state.isCanceledQuestion ? this.state.canceledReason : this.state.answerExplainer}</div>
                        <div className="qa-header">Value</div>
                        <div className="qa-text">{this.state.value} {this.state.value === 1 ? "point" : "points"}</div>
                        <div className="game-broadcast-info">{this.renderCellModalBroadcastInfo()}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.handleCloseSinglePickModal() }}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showSinglePlayerPickemPicks} onHide={() => this.handleClosePlayerPickemPicksModal()}>
                    <Modal.Header closeButton>
                        <h5>
                            <div>{this.state.currentPlayer.firstName} Picks</div>
                            <div className="picks-modal-team-name">{this.state.currentPlayer.teamName}</div>
                            <div className="picks-modal-game-name">{this.state.game && this.state.game.gameName} ({this.state.game && this.renderPicksModalWeekDates(this.state.game.validGameWindowStartUnix, this.state.game.validGameWindowStartUnix)})</div>
                        </h5>
                    </Modal.Header>
                    <Modal.Body className="picks-modal-body">
                        {this.renderPlayerPicksModalBody(this.state.game, this.state.currentPlayer)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.handleClosePlayerPickemPicksModal() }}>Close</Button>
                    </Modal.Footer>
                </Modal>
                {this.renderScores(filters)}
            </div>
        )
    }

    renderCurrentScoreboard = () => {
        switch (this.state.currentScoreboardMode) {
            case this.SCOREBOARD_MODES.SINGLE_GAME_ALL:
                return this.renderSingleGameScoreboard();
            case this.SCOREBOARD_MODES.SINGLE_GAME_KIDS:
                return this.renderSingleGameScoreboard({ isKidsOnly: this.state.currentScoreboardMode === this.SCOREBOARD_MODES.SINGLE_GAME_KIDS });
            case this.SCOREBOARD_MODES.SEASON:
                return this.renderSeasonScoreboard();
            default:
                return this.renderSingleGameScoreboard()
        }
    }

    render() {
        return (
            <>
                {this.renderScoreboardTypeButtons()}
                {this.renderCurrentScoreboard()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        gameEntries: state.gameEntries,
        game: state.game.currentGame
    }
}

export default withRouter(connect(mapStateToProps, {
    fetchEntriesByPickemGameId,
    fetchGameByGameId,
    getSetScoreboard
})(Scoreboard))